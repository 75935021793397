<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Large localization"
      store="largeLocalization"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'large-localizations.show',
            params: { largeLocalizationId: id }
          })
      "
    />
    <large-localization />
  </v-card>
</template>

<script type="text/babel">
import largeLocalizationModule from "@/store/modules/large-localization";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import LargeLocalization from "../../components/large-localization/LargeLocalization";

const { mapGetters } = createNamespacedHelpers("largeLocalization");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "largeLocalization",
  largeLocalizationModule
);

export default {
  name: "large-localization-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    LargeLocalization,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name", "id"])
  },
  ...detailViewLifecycleMethods
};
</script>
