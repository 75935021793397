<template>
  <table-overview
    title="Large Localizations"
    store="largeLocalizations"
    :headers="[
      { text: 'Name', value: 'name' },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'large_localizations_view'
      }
    ]"
    :actions="actions"
    force-initial-sort
  >
    <large-localization-table-row
      slot="table-row"
      slot-scope="{ item }"
      :large-localization-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import largeLocalizationsModule from "@/store/modules/large-localizations";
import { mapOverviewLifecycleMethods } from "../helpers";
import LargeLocalizationTableRow from "@/components/large-localization/LargeLocalizationTableRow";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "largeLocalizations",
  largeLocalizationsModule
);

export default {
  name: "large-localizations-view",
  components: {
    LargeLocalizationTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "large-localizations.create" },
        rolesOrPermissions: "large_localization_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
