<template>
  <v-container fluid>
    <v-text-field
      v-model="name"
      v-validate="'required|max:255'"
      label="name"
      :counter="255"
      required
      :error-messages="errors.collect('name')"
      data-vv-name="name"
    />
    <v-radio-group v-model="locale" row>
      <v-radio label="nl_be" value="nl_be" />
      <v-radio label="nl_nl" value="nl_nl" />
      <v-radio label="de_de" value="de_de" />
      <v-radio label="en_gb" value="en_gb" />
      <v-radio label="fr_be" value="fr_be" />
      <v-radio label="fr_fr" value="fr_fr" />
    </v-radio-group>
    <quill-editor
      v-if="locale === 'nl_be'"
      ref="textEditor"
      v-model="nl_be"
      :options="editorOption"
    />
    <quill-editor
      v-if="locale === 'nl_nl'"
      ref="textEditor"
      v-model="nl_nl"
      :options="editorOption"
    />
    <quill-editor
      v-if="locale === 'en_gb'"
      ref="textEditor"
      v-model="en_gb"
      :options="editorOption"
    />
    <quill-editor
      v-if="locale === 'de_de'"
      ref="textEditor"
      v-model="de_de"
      :options="editorOption"
    />
    <quill-editor
      v-if="locale === 'fr_be'"
      ref="textEditor"
      v-model="fr_be"
      :options="editorOption"
    />
    <quill-editor
      v-if="locale === 'fr_fr'"
      ref="textEditor"
      v-model="fr_fr"
      :options="editorOption"
    />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
const { mapComputed } = createNamespacedHelpers("largeLocalization");

export default {
  name: "large-localization",
  inject: ["$validator"],
  data: () => ({
    locale: "nl_be",
    editorOption: {
      theme: "snow",
      modules: {
        markdownShortcuts: {},
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          ["image"],
          ["link"]
        ]
      }
    }
  }),
  computed: {
    ...mapComputed([
      "name",
      "nl_be",
      "nl_nl",
      "de_de",
      "en_gb",
      "fr_be",
      "fr_fr"
    ])
  }
};
</script>
