<template>
  <tr>
    <td>
      {{ largeLocalization.name }}
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'large-localizations.show',
          params: { largeLocalizationId: largeLocalization.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapLargeLocalizationGetters } = createNamespacedHelpers(
  "largeLocalizations"
);

export default {
  name: "large-localization-table-row",
  props: {
    largeLocalizationId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapLargeLocalizationGetters([FIND_BY_ID]),
    largeLocalization() {
      return this[FIND_BY_ID](this.largeLocalizationId);
    }
  }
};
</script>
